<script>
  import RepgenDataService from "@/services/RepgenDataService";
  import { repgen } from "@/firebase";
  import repgenSections from "@/data/RepgenSections";
  import { collection, getDocs, onSnapshot, where, query, writeBatch, doc } from "@firebase/firestore";
  const clientsDB = collection(repgen, "clients");

  export default {
    props: ["archived"],
    data() {
      return {
        uri: process.env.VUE_APP_MSAL_REDIRECTURI,
        clients: [],
        activeClients: [],
        orderedClients: [],
        questions: [],
        sections: [],
        clientName: "",
        questionsRef: [],
        questionID: "",
        keyID: 0,
        user: [],
        repgenSections: repgenSections,
      };
    },

    mounted() {
      this.fetchClients();
    },
    methods: {
      async fetchClients() {
        const q = query(clientsDB, where("deleted", "==", this.archived));
        onSnapshot(q, (snap) => {
          snap.forEach((doc) => {
            const client = doc.data();
            client.id = doc.id;
            this.clients.push(client);
          });
        });
      },
      async addNewClient() {
        const created = new Date();
        let data = {
          id: this.clientName,
          date: created,
          deleted: false,
        };

        // @/services/RepgenDataService.js
        await RepgenDataService.create(data, this.clientName)
          .then(() => {
            console.log("Created new client repgen successfully!");
          })
          .catch((e) => {
            console.log(e);
          });
        // await clientsDB.doc(this.clientName).set(data);

        this.addQuestionsToClient();
        this.$router.push({ name: 'clientRepgen', params: {clientID: this.clientName} });
      },
      async addQuestionsToClient() {
        var i;
        var title;
        var batch = writeBatch(repgen)
        var batchWrites = 0

        for (i = 0; i < this.repgenSections.length; i++) {
          this.questions = [];
          title = this.repgenSections[i].title;
          const docs = await getDocs(collection(repgen, "questions", "sections", title));
          docs.forEach((doc) => {
            const question = doc.data();
            this.questions.push(question);
          });

          for (var j = 0; j < this.questions.length; j++) {
            batch.set(doc(collection(repgen, "clients", this.clientName, title)), {
              rowId: this.questions[j].rowId,
              question: this.questions[j].question,
              order: this.questions[j].order,
              active: this.questions[j].active,
              likert: null,
              tips: this.questions[j].tips,
              recommended: true,
              comments: [],
            });
            batchWrites++;
            if(batchWrites >= 500) {
              batch.commit();
              batch = writeBatch(repgen)
              batchWrites = 0
            }
          }
        }
        if(batchWrites > 0) batch.commit()
      },
    },
    computed: {
      sortedByDate() {
        var value = this.clients;
        return value.sort((a, b) => new Date(b.date.seconds) - new Date(a.date.seconds));
      },
    },
  };
</script>

<template>
  <div class="home">
    <div class="flex items-center" v-if="!archived">
      <input
        v-on:keyup.enter="addNewClient()"
        class="h-10 px-2 border border-gray-300 rounded "
        type="text"
        v-model="clientName"
      />
      <button @click="addNewClient()" class="flex items-center h-10 px-3 ml-3 text-sm text-white bg-blue-400 rounded">
        <svg
          class="w-5 h-5 mr-1"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
        Add New
      </button>
    </div>

    <div class="grid grid-cols-1 gap-4 mt-12 sm:grid-cols-4">
      <router-link
        :to="{name: 'clientRepgen', params: { clientID: client.id }}"
        v-for="(client, index) in sortedByDate"
        :key="index"
        class="relative flex items-center px-6 py-5 space-x-3 bg-blue-300 rounded-lg hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
      >
        <div class="flex-1 min-w-0">
          <span class="absolute inset-0" aria-hidden="true"></span>
          <p class="font-medium text-white uppercase ">
            {{ client.id }}
          </p>
        </div>
      </router-link>
    </div>
    <!-- <pre>

    {{ activeClients }}
    </pre> -->
  </div>
</template>
