<script>
  import RepgenList from "../components/repgen/RepgenList.vue";

  export default {
    components: { RepgenList }
  }
</script>

<template>

  <div>
    <RepgenList :archived="false" />
  </div>
  <br /><br />

  <div>
    <router-link :to="{name: 'RepGenArchive' }" class="px-3 py-2 text-white bg-blue-400 rounded text-md">
      <span class="text-md">&#10094;&#10094;</span>&nbsp;&nbsp; All Archived Repgens
    </router-link>
  </div>
</template>
